* {
    margin: 0;
    padding: 0;
    font-family: 'Fredoka', sans-serif;
    box-sizing: border-box;
    --prim: #0044ff;
}

body {
    background: var(--prim)!important;
}

#root {
    background-color: var(--prim);
}

h1 {
    color: white;
    font-family: 'Inter';
}

.login-nav > input {
    margin-right: 1rem;
}

.item {
    padding: 5rem;
}

input {
    padding: 0.75rem; 
    margin-right: 2rem;
    border-radius: 0.25rem;
    border:0px !important;
}

input[type=text]:focus {
    /* color: white; */
    background-color: #f1f0ff;
  }
  /* Homepage */
  .homePage {
      color: white;
  }

  .homePage.line{
      border-bottom:3px solid #f1f0ff;
      width: 50%;
      /* background-color: #ff0000; */
  }

  input[type=file]{
    display: none;
  }

  .upload {
      background-color: #ff0000;
  }
  
  @media screen {
    .homePage {
        margin-top: 1rem;
        margin-left: 10rem;
    }
    .logReg {
        display: flex;
        flex-direction: row;
    }
      /* Stocks Dashboard */
    .content {
        margin: auto;
        width: 50%;
        max-width: 400px;
    }    
}

@media (max-width: 600px) {
    .homePage {
        margin-top: 1rem;
        margin-left: 1rem;
    }
    .logReg {
        display: block;
        /* flex-direction: column; */
    }  
    button.ui.button {
        color: white;
        margin-top: 1rem;
    }
    button.ui.button.on {
        color: var(--prim);
        background-color: white;
        /* border: 2px solid black; */
        /* margin-top: 1rem; */
    }
      /* Stocks Dashboard */
    .content {
    margin: auto;
    width: 80%;
    max-width: 300px;
    }
}

.stockList {
    /* margin-top: 5rem; */
}

.stockBar {
    /* background-color: #3761ec; */
    margin: -3rem 0 3rem 0;
}

.newStock {
    width: 100%;
    /* background-color: #3c49ff; */
}

/* Footer */
.logoutFooter {
    padding-bottom: 5rem;
}

/* UI COMPONENTS */

.ui.form {
    margin-top: 5rem;
}

/* .ui.buttons .button.active {
    background-color: #3c49ff;
    color: white;
} */

button.ui.button {
    color: white;
    margin-right: 1rem;
}
button.ui.button.on {
    color: var(--prim);
    background-color: white;
    /* border: 2px solid black; */
    margin-right: 1rem;
}

button.ui.button:hover {
    background-color: white;
    /* color: white; */
}

button.ui.button.on:hover{
    background-color: black;
    color: white;
}

.ui.button.delete{
    background-color: var(--prim);
    margin: 0;
    padding: .5rem;
}
.ui.button.delete:Hover{
    background-color: #ff0000;
    padding: .5rem;
}

.ui.inverted.top.fixed.menu {
    background-color: #2B2C30;
    /* margin-bottom: 5rem; */
}


.ui.celled.table {
    /* background-color: var(--prim); */
    font-weight: bold;
    border: 1px solid #ffffff;
    text-align: right;
}

.ui.celled.table .ticker {
    text-align: left;
}


td {
    border: 1px solid #ffffff;
}

.ui.inverted.top.fixed.menu {

}

.contact {
    color: white;
    font-weight: bold;
    padding: 0.45rem 1rem;
    border: 2.5px solid white;
    border-radius: .25rem;
    /* background-color: #f2ff7a; */
}

.contact:hover {
    color: black;
    background-color: white;
}

/* tr.table-footer {
    background-color: #F9FAFB;
    font-weight: bolder;
} */

